import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo";

export const query = graphql`
  query {
    wpgraphql {
      posts(where: {categoryName: "netlify"}, first: 25) {
        nodes {
          id
          title
          uri
          excerpt
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
`

const Blog = ({ data }) => {
  const posts = data.wpgraphql.posts.nodes
  
  return (
    <Layout>
      <Seo />
      <div w="95%|95%|90%|80%|80%">
        {posts.map(post => (
          <article key={post.id}>
            <div className="featured-thumbnail">    
              <Link
                to={`/blog${post.uri}`}
                dangerouslySetInnerHTML={{__html: `<img src=${post.featuredImage.node.sourceUrl} alt="" class="featured-thumbnail" />` }}
              />
            </div>
            <div className="postMeta">
              <h2 className="title">
                <Link
                  to={`/blog${post.uri}`}
                  dangerouslySetInnerHTML={{ __html: post.title }}
                />
              </h2>
              <div dangerouslySetInnerHTML={{ __html: post.excerpt }} />
            </div>
          </article>
        ))}
      </div>
    </Layout>
  )
}

export default Blog